<template>
  <div class="reset_container">
    <div v-show="showModal" class="sign_up_modal">
      <div class="sign_up_modal_back">
      </div>
      <div class="sign_up_modal_front">
        <div class="sign_up_modal_text">
          <div class="sign_up_confirm_mail">{{ $t('You_need_to_confirm_your_email_to_continue') }}</div>
          <div class="sign_up_please_confirm">{{ $t('Please_check_your_mail') }}</div>
        </div>
        <div @click="$bvModal.show('feedback')" class="sign_up_not_receive">{{
            $t('I_did_not_receive_letter')
          }}
        </div>
        <a href="/signin" class="sign_up_close_form" @click="showModal = false"></a>
      </div>
    </div>
    <b-modal id="feedback" hide-footer size="lg">
      <div class="feedback-wrapper-modal">
        <div class="feedback-element">
          <h3>{{ $t("Leave your message for feedback") }}</h3>
        </div>
        <div class="feedback-element">
          <span>{{ $t("Full name") }}</span>
          <input v-model="data.name" />
        </div>
        <div class="feedback-element">
          <span>{{ $t("Phone") }}</span>
          <input v-model="data.phone" v-mask="'+7 (###) ### ##-##'" />
        </div>
        <div class="feedback-element">
          <span>{{ $t("Email") }}</span>
          <input v-model="data.email" />
        </div>
        <div class="feedback-element">
          <span>{{ $t("Message") }}</span>
          <textarea v-model="data.message" style="    border-radius: 10px;
    padding: 8px;"></textarea>
        </div>
        <div class="feedback-element">
          <vue-recaptcha @verify="toggleCaptcha" sitekey="6Le4a80jAAAAAIsLHciqcozvWuzy4bzyXl3FAX8R"></vue-recaptcha>
        </div>

        <div class="feedback-send">
          <el-button @click="send">{{ $t("Send") }}</el-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import LoginForm from '@/components/LoginForm';
import VueMask from 'v-mask'
import {VueRecaptcha} from "vue-recaptcha";
import {minLength, required} from "vuelidate/lib/validators";

export default {
  name: 'Login',
  components: {
    LoginForm,
    VueRecaptcha
  },
  data() {
    return {
      showModal: true,
      data: {
        name: '',
        phone: '',
        message: '',
        email: ''
      },
    }
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  mounted() {
  },
  methods: {
    toggleCaptcha() {
      this.captureButton = !this.captureButton;
    },
    send() {
      if (!this.captureButton) {
        Vue.toastr({
          description: 'Подтвердите что вы не робот',
          type: 'error'
        })
        return
      }

      function validateEmail(email) {
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase());
      }

      if (!validateEmail(this.data.email)) {
        Vue.toastr({
          description: 'Неверно указан имейл',
          type: 'error'
        })
        return
      }

      this.$http.post(`${API_ROOT}/api/feedback`, this.data)
        .then(() => {
          this.$bvModal.hide('feedback')
          this.data = {
            name: '',
            phone: '',
            message: '',
            email: ''
          };
          this.captureButton = false;

          Vue.toastr({
            description: 'Сообщение успешно отправлено',
            type: 'success'
          });
        })
        .catch(err => {
          this.captureButton = false;
          console.error(err);
        })
    },
  },
}
</script>
